<template>
    <div :class="{['mobile-dropdown']: true, [`sub-level-${subLevel.toString()}`]: true}">
        <div
            v-for="(link, index) in links"
            :key="`mobile-dropdown-link-${index}`"
            :class="{['mobile-dropdown-link']: true, ['active']: activeMenu.selected == `mobile-dropdown-${index}-${subLevel.toString()}`}"
        >
            <a v-if="!link.children || !Object.keys(link.children).length" :href="link.url" target="_blank">
                {{ link.title }}
            </a>
            <span v-else @click="hoverActiveMenu(`mobile-dropdown-${index}-${subLevel.toString()}`, link)">
                {{ link.title }}

                <svg :class="{['active']: activeMenu.selected == `mobile-dropdown-${index}-${subLevel.toString()}`}" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 7.5L10 12.5L15 7.5H5Z" fill="#13314E"/>
                </svg>
            </span>
            <MobileDropdown v-if="link.children && Object.keys(link.children).length && activeMenu.selected == `mobile-dropdown-${index}-${subLevel.toString()}`" :links="link.children" :sub-level="subLevel+1" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MobileDropdown',
        components: {
            MobileDropdown: () => import('./MobileDropdown.vue')
        },
        props: {
            links: {
                type: Object
            },
            subLevel: {
                type: Number,
                default: 1
            }
        },
        data() {
            return {
                activeMenu: {
                    selected: '',
                    menu: {}
                }
            }
        },
        computed: {

        },
        methods: {
            hoverActiveMenu(key, menu) {
                if (menu.children && Object.keys(menu.children).length && key != this.activeMenu.selected) {
                    this.activeMenu.selected = key;
                    this.activeMenu.menu = menu;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import "../../../styles/main.scss";

    .mobile-dropdown {
        font-family: $font_family;
        display: none;

        @media (max-width: 1199.98px) {
            display: flex;
            flex-direction: column;
            background-color: rgba(255,255,255,0.1);
            margin-top: 8px;
            padding: 8px 10px;
            width: 100%;
        }

        &-link {
            margin-bottom: 8px;

            a,
            span {
                color: #fff;
                display: block;
                width: 100%;

                &:focus,
                &:focus-visible,
                &:focus-within,
                &:hover {
                    text-decoration: none;
                }

                svg {
                    transition-duration: 300ms;
                    transition-property: transform;

                    path{
                        fill:white;
                    }

                    &.active {
                        transform: rotate(180deg);
                    }
                }
            }

            &:last-child {
                margin-bottom: 0px;
            }
        }
    }
</style>